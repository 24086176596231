import { gql } from "@apollo/client";
import { BriefFields } from "internal/shared/fragments/graphql-api/brief";
import { BriefAspectRatioAdUnitFields } from "internal/shared/fragments/graphql-api/briefAspectRatioAdUnit";
import { TaskFields } from "internal/shared/fragments/graphql-api/task";
import { Rating } from "internal/shared/fragments/graphql-api/video";
import { ViewOptions } from "internal/shared/fragments/graphql-api/viewOptions";
import { BrandFields } from "shared/fragments/brand";
import {
  VideoFieldsWithoutNotes,
  VideoFieldsWithoutVariants,
  VideoVariantVersionsFields,
} from "shared/fragments/video";

export const TaskViewVideoFields = gql`
  fragment TaskViewVideoFields on Video {
    ...Rating
    ...VideoFieldsWithoutNotes
  }
  ${Rating}
  ${VideoFieldsWithoutNotes}
`;

export const GET_TASK_VIEW = gql`
  query GetTaskView($shortHash: ID!, $projectID: ID!) {
    brief(shortHash: $shortHash) {
      ...BriefFields
      aspectRatioAdUnits {
        ...BriefAspectRatioAdUnitFields
      }
      brand {
        ...BrandFields
      }
      complete
      languages
      numberOfConcepts
      submissionDate
    }
    project(id: $projectID) {
      id
      briefID
      creativeConcepts {
        id
        default
        name
        videos {
          id
          action
          concept
          creatorName
          gifURI
          notes {
            approval
          }
          order
          originalFilename
          rating
          shortHash
          status
          thumbnailURI
          videoVariants {
            id
            adUnit
            aspectRatio
            assetType
            hidden
            language
            thumbnail
            versions {
              ...VideoVariantVersionsFields
              assets {
                id
                assetIndex
                transcoded
                type
                videoURI
                videoURIHDR
              }
            }
          }
        }
      }
      currentTask {
        ...TaskFields
      }
      viewOptions @client {
        ...ViewOptions
      }
    }
  }
  ${BrandFields}
  ${BriefAspectRatioAdUnitFields}
  ${BriefFields}
  ${TaskViewVideoFields}
  ${ViewOptions}
  ${VideoFieldsWithoutVariants}
  ${VideoVariantVersionsFields}
  ${TaskFields}
`;

export default {
  GET_TASK_VIEW,
};
