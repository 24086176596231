import getVariant from "shared/utils/getVariant";

interface IVariant {
  adUnit: string;
  aspectRatio: string;
  id: string;
  language: string | null;
  sequenceOrder: number;
}

export const getItems = <V extends IVariant | null>(
  variants: V[] | null,
  adUnit: string,
  aspectRatio: string,
  language: string | null,
) => {
  if (variants === null) {
    return [];
  }

  const adUnitVariants = variants.filter(
    (variant: any) => variant.adUnit === adUnit,
  );

  if (adUnitVariants.some((variant: any) => variant.sequenceOrder > 0)) {
    return adUnitVariants
      .filter(
        (variant) =>
          variant?.aspectRatio === aspectRatio &&
          (language === null || variant.language === language),
      )
      .sort((a, b) => {
        if (a === null || b === null) {
          return 0;
        }

        return a.sequenceOrder - b.sequenceOrder;
      });
  }

  return [getVariant(variants, adUnit, aspectRatio, language)];
};

export default getItems;
