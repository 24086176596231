interface IVariant {
  adUnit: string;
  aspectRatio: string;
  id: string;
  language: string | null;
  sequenceOrder: number;
}

export const getVariant = <V extends IVariant | null>(
  variants: Array<V>,
  adUnit: string,
  aspectRatio: string,
  language: string | null,
) => {
  // Try and find an exact match first
  let result = variants.find(
    (variant) =>
      variant !== null &&
      variant.adUnit === adUnit &&
      variant.aspectRatio === aspectRatio &&
      variant.language === language,
  );

  // Try and find a match for ad unit and aspect ratio
  if (!result) {
    result = variants.find(
      (variant) =>
        variant !== null &&
        variant.adUnit === adUnit &&
        variant.aspectRatio === aspectRatio,
    );
  }

  // Try and find a match for ad unit
  if (!result) {
    result = variants.find(
      (variant) => variant !== null && variant.adUnit === adUnit,
    );
  }

  if (result) {
    return result;
  }

  return variants[0];
};

export default getVariant;
