import Environment from "shared/types/environment";
import processEnvWarning from "shared/utils/processEnvWarning";

processEnvWarning(["AUDIENCE", "CLIENT_ID", "DOMAIN", "REALM"], "AUTH0");

export const AUTH = {
  [Environment.Development]: {
    AUDIENCE: process.env.AUTH0_AUDIENCE || "https://staging.vidsy.co",
    CLIENT_ID:
      process.env.AUTH0_CLIENT_ID || "fCvNDe4fVUdaSnMZk4OyNCoWj8ED9lO5",
    DOMAIN: process.env.AUTH0_DOMAIN || "login.vidsy.co",
    REALM: process.env.AUTH0_REALM || "brands-staging",
    REDIRECT_URI:
      process.env.AUTH0_REDIRECT_URI ||
      "https://api.staging.vidsy.co/v1/auth/callback",
  },
  [Environment.Live]: {
    AUDIENCE: "https://vidsy.co",
    CLIENT_ID: "tSvBpSMFKIACZYZcKj3PKPgK8SVbKhRi",
    DOMAIN: "login.vidsy.co",
    REALM: "brands-live",
    REDIRECT_URI: "https://api.vidsy.co/v1/auth/callback",
  },
  [Environment.Staging]: {
    AUDIENCE: process.env.AUTH0_AUDIENCE || "https://staging.vidsy.co",
    CLIENT_ID:
      process.env.AUTH0_CLIENT_ID || "fCvNDe4fVUdaSnMZk4OyNCoWj8ED9lO5",
    DOMAIN: process.env.AUTH0_DOMAIN || "login.vidsy.co",
    REALM: process.env.AUTH0_REALM || "brands-staging",
    REDIRECT_URI:
      process.env.AUTH0_REDIRECT_URI ||
      "https://api.staging.vidsy.co/v1/auth/callback",
  },
};

export default AUTH;
