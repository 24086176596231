import { gql } from "@apollo/client";
import { ViewOptions } from "internal/shared/fragments/graphql-api/viewOptions";

export const GET_SELECT_VIDEOS_PROJECT = gql`
  query GetSelectVideosProject($id: ID!) {
    project(id: $id) {
      id
      briefID
      creativeConcepts {
        id
        default
        name
        requiredVideos
        videos {
          id
          action
          concept
          creatorID
          creatorName
          gifURI
          internal
          latestVersionVisible
          notes {
            approval
          }
          order
          originalFilename
          ownershipChanged
          rating
          shortHash
          status
          thumbnailURI
          videoVariants {
            id
            clientVisible
            hidden
            language
            versions {
              id
              assets {
                id
                type
              }
            }
          }
          visible
        }
      }
      office
      requiredVideos
      selectedVideoIds @client
      tasks {
        id
        dueDate
        kind
        state
        title
      }
      viewOptions @client {
        ...ViewOptions
      }
    }
  }
  ${ViewOptions}
`;

export default {
  GET_SELECT_VIDEOS_PROJECT,
};
