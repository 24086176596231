import { useContext, useMemo } from "react";
import { MediaType } from "shared/types/graphql";
import getItems from "shared/utils/getItems";
import { VideoManagementStateContext } from "shared/utils/getVideoManagementContext";

const useIsStillImage = () => {
  const { adUnit, aspectRatio, itemIndex, language, videoIndex, videos } =
    useContext(VideoManagementStateContext);

  const stillImage = useMemo(() => {
    const currentVideoObject = videos[videoIndex];

    const items =
      currentVideoObject &&
      getItems(videos[videoIndex]?.variants, adUnit, aspectRatio, language);

    return items?.[itemIndex]?.type === MediaType.Image;
  }, [adUnit, aspectRatio, itemIndex, language, videoIndex, videos]);

  return stillImage;
};

export default useIsStillImage;
