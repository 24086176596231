import { createContext } from "react";
import { IAction } from "shared/types/actions";
import { Direction } from "shared/types/direction";
import {
  IBaseVideoManagementState,
  IVideoManagementState,
} from "shared/types/video";

const baseState = {
  videoIndex: 0,
  videos: [],
};

export const initialVideoManagementState: IVideoManagementState = {
  ...baseState,
  adUnit: "",
  aspectRatio: "",
  direction: Direction.Forwards,
  hideDoneFeedback: false,
  itemIndex: 0,
  language: "",
  preventNavigation: false,
  versionIndex: 0,
};

const getVideoManagementDispatchContext = () =>
  createContext<React.Dispatch<IAction>>(() => null);

const getVideoManagementStateContext = <S extends IBaseVideoManagementState>(
  state: S,
) => createContext<S>(state);

export const VideoManagementDispatchContext =
  getVideoManagementDispatchContext();
export const VideoManagementStateContext = getVideoManagementStateContext(
  initialVideoManagementState,
);

export default {
  getVideoManagementDispatchContext,
  getVideoManagementStateContext,
};
