import { lazy } from "react";
import { IRouteGroup } from "shared/types/routes";

const QualityReport = lazy(() => import("./pages/QualityReport"));
const SelectVideos = lazy(() => import("./pages/SelectVideos"));
const TaskView = lazy(() => import("./pages/TaskView"));

export const routes: IRouteGroup = {
  name: "curation",
  routes: [
    {
      authRequired: true,
      component: TaskView,
      fullscreen: true,
      path: "/:briefShortHash/videos/:shortHash",
    },
    {
      authRequired: true,
      component: SelectVideos,
      path: "/:briefShortHash/videos",
    },
    {
      authRequired: true,
      component: QualityReport,
      path: "/:briefShortHash/quality-report",
    },
  ],
};

export default routes;
