import { IVideoTimes } from "shared/types/video";

export const generateTimeBars = (
  currentVideo: number,
  noOfVideos: number,
  stillImage: boolean,
  videoTimes: IVideoTimes,
  offsetHeight = 10,
) => {
  const totalWidth = 358;
  const gapWidth = 5;
  const perVideoWidth = (totalWidth - gapWidth * noOfVideos) / noOfVideos;

  const timeBars = [];

  for (let i = 0; i < noOfVideos; i++) {
    const startPosition = 8.5 + perVideoWidth * i + gapWidth * i;
    let progressWidth;

    if (currentVideo === i) {
      let ratio = videoTimes ? videoTimes.ratio : 1;
      progressWidth = Math.ceil(perVideoWidth * ratio);
      if (stillImage) {
        ratio = 0;
        progressWidth = Math.ceil(perVideoWidth * ratio);
      }
    } else if (currentVideo > i) {
      progressWidth = perVideoWidth;
    }

    timeBars.push(
      <g fill="none" key={i}>
        <path
          d={`M${startPosition} ${offsetHeight}h${perVideoWidth} `}
          stroke="#fff"
          strokeLinecap="round"
          strokeMiterlimit={10}
          strokeOpacity="0.33"
          strokeWidth={2}
        />
        {progressWidth && (
          <path
            d={`M${startPosition} ${offsetHeight}h${progressWidth}`}
            stroke="#fff"
            strokeLinecap="round"
            strokeMiterlimit={10}
            strokeWidth={2}
          />
        )}
      </g>,
    );
  }

  return timeBars;
};

export default generateTimeBars;
